<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseInput
		:value="value"
		class="p-0 confirm-input"
		:type="type"
		:label="label"
		data-vv-validate-on="blur"
		validation-id="input_confirm_validation"
		:validation-rules="{ required: true, is: confirmation }"
		:validation-messages="{
			required: requiredError,
			is: confirmationErrorComp
		}"
		:automation-id="getAutomationId('confirm')"
		aria-required="true"
		:data-vv-as="confirmation"
		@input="updateInput"
		><template #labelButton class="passwordButton">
			<BaseButton
				class="m-0 p-0 float-right password-button"
				:label="buttonText"
				data-tracking-id="password-field-visibility-button"
				:aria-label="buttonText"
				:automation-id="getAutomationId('password')"
				variant="link"
				icon-position="left"
				:icon="icon"
				@click="ClickIcon"
			/>
		</template>
	</BaseInput>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseInput from '@/components/common/base/BaseInput';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id.js';
import { required, is } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('is', is);

// @vue/component
@Component({
	name: 'ConfirmInput',
	components: {
		BaseInput,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		label: {
			type: String,
			default: ''
		},
		field: {
			type: String,
			default: '',
			required
		},
		confirmation: {
			type: String,
			default: ''
		},
		confirmationError: {
			type: String,
			default: null
		},
		password: {
			type: Boolean,
			default: true
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class ConfirmInput extends Vue {
	isShowing = false;
	data() {
		return {
			value: '',
			icon: ['fal', 'eye'],
			type: 'password'
		};
	}

	/**
	 * Returns a locale value to prevent accessing a property from a null object.
	 */
	get localValue() {
		return this.value ? this.value : {};
	}

	get requiredError() {
		return this.password
			? this.$t('error.passwordRequired')
			: this.$t('error.required', { 0: this.label });
	}

	get confirmationErrorComp() {
		if (this.password) {
			return this.$t('error.password');
		}
		return this.confirmationError
			? this.confirmationError
			: this.$t('error.confirmation', { 0: this.field });
	}

	get buttonText() {
		return this.isShowing ? this.$t('button.invisible') : this.$t('button.visible');
	}

	/**
	 *handles passing  of value from child to parent
	 */
	updateInput(input) {
		this.value = input;
		this.$emit('input', input);
	}

	/**
	 * handles click event for text visibility
	 */
	ClickIcon() {
		this.isShowing = !this.isShowing;
		if (this.isShowing) {
			this.icon = ['fal', 'eye-slash'];
			this.type = 'text';
		} else {
			this.icon = ['fal', 'eye'];
			this.type = 'password';
		}
	}
}
</script>
<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
* ::v-deep .input-group-append {
	height: 20px;
	max-height: 20px;
	width: 20px;
	max-width: 20px;
	position: absolute;
	right: 0;
	margin-top: 5px;
	margin-right: 15px;
	z-index: 4;
}
* ::v-deep .btn-link {
	height: 25px;
	color: $blue;
	text-decoration: none;
	& p {
		padding-top: 0;
		&:hover {
			color: $blue;
			text-decoration: none;
		}
	}
}

.confirm-input ::v-deep .label-div {
	justify-content: space-between;
}
</style>
<!-- *************************************************************************
	TRANSLATION
	************************************************************************* -->
<i18n>
{
    "en": {
		"button": {
			"visible": "Show",
			"invisible": "Hide"
		},
        "error": {
			"required": "{0} is required",
			"passwordRequired": "Please confirm your password.",
			"password": "The passwords don't match. Please enter the new password in both text boxes.",
            "confirmation": "{0} is not the same as first field."
        }
    },
    "fr": {
		"button": {
			"visible": "Afficher",
			"invisible": "Masquer"
		},
    	"error": {
			"required": "Veuillez entrer un {0}",
			"passwordRequired": "Veuillez confirmer votre mot de passe.",
			"password": "Les mots de passe ne correspondent pas. Entrez le nouveau mot de passe dans les deux champs.",
      		"confirmation": "(TODO FR){0} is not the same as first field."
        }
    }
}
</i18n>
