<template>
	<div>
		<p class="password-requirements-title">{{ $t('password.title') }}</p>

		<ul class="pl-3">
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-v-html vue/no-v-html -->
			<li class="password-requirement-list" v-html="$t('password.requirementOne')"></li>
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-v-html vue/no-v-html -->
			<li class="password-requirement-list" v-html="$t('password.requirementTwo')"></li>
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-v-html vue/no-v-html -->
			<li class="password-requirement-list" v-html="$t('password.requirementThree')"></li>
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-v-html vue/no-v-html -->
			<li class="password-requirement-list" v-html="$t('password.requirementFour')"></li>
		</ul>
	</div>
</template>

<script>
// This component renders the list of password requirements.
import Vue from 'vue';
import Component from 'vue-class-component';
// @vue/component
@Component({
	name: 'PasswordRequirements'
})
export default class PasswordRequirements extends Vue {}
</script>

<style lang="scss" scoped>
.password-requirements-title {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: bold;
	color: $black;
	margin-bottom: 8px;
}
.password-requirement-list {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	padding-bottom: 4px;
}
</style>

<i18n>
{
    "en": {
		"password": {
			"title": "Password requirements",
			"requirementOne": "must be a <b>minimum of 8 characters</b> (50 max)",
			"requirementTwo": "at least <b>one lower case character</b>",
			"requirementThree": "at least <b>one upper case character</b>",
			"requirementFour": "at least <b>one number</b>"
		}
    },
    "fr": {
		"password": {
			"title": "Exigences pour le mot de passe ",
			"requirementOne": "Doit contenir un <b>minimum de 8 caractères</b> (maximum de 50)",
			"requirementTwo": "Doit contenir au moins <b>une minuscule</b>",
			"requirementThree": "Doit contenir au moins <b>une majuscule</b>",
			"requirementFour": "Doit contenir au moins <b>un chiffre</b>"
		}
    }
}
</i18n>
